import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Introduction",
  "description": "The introduction of the Use Less Divs Blog",
  "lead": "I’ve been planning for a long time to create this blog and finally it’s time to get started. Let me tell you a little bit about the blog and myself.",
  "dateCreated": "2021-07-26T00:00:00.000Z",
  "slug": "introduction",
  "postUrl": "https://uselessdivs.com/blog/introduction/",
  "ogImg": "https://uselessdivs.com/img/introduction/og-img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About the blog`}</h2>
    <p>{`Use Less Divs blog is primarily built for web devs, but it will be useful for anyone, who want to learn more about `}<strong parentName="p">{`web accessibility.`}</strong></p>
    <p>{`If I find something interesting, I will share it with you.`}</p>
    <p>{`During the time you spend on this site, you will find the following:`}</p>
    <ul>
      <li parentName="ul">{`Interesting blog posts about accessibility`}</li>
      <li parentName="ul">{`My new favourite articles under the `}<a parentName="li" {...{
          "href": "/read/"
        }}>{`Reading List`}</a>{` section`}</li>
      <li parentName="ul">{`Quick updates about my learning journey`}</li>
      <li parentName="ul">{`And probably a bunch of other things.`}</li>
    </ul>
    <p>{`This blog was built with `}<strong parentName="p">{`Gatsby`}</strong>{`, `}<strong parentName="p">{`React`}</strong>{` (this is the first time I worked with them), and used `}<strong parentName="p">{`Sass`}</strong>{` and the `}<strong parentName="p">{`BEM`}</strong>{` methodology.`}</p>
    <p>{`Fun fact: this is the first post on this site.`}</p>
    <p>{`The design was created by a good friend of mine. He's a very talented artist, so check out his other works on his `}<a href="https://www.behance.net/ekoliger/" rel="noreferrer" target="_blank">{`Behance`}</a>{` and `}<a href="https://www.instagram.com/koen.769/" rel="noreferrer" target="_blank">{`Instagram`}</a>{`.`}</p>
    <h2>{`About me`}</h2>
    <p>{`Hey, my name is Joco (/yotso/). I'm an `}<strong parentName="p">{`accessibility enthusiast`}</strong>{` and a `}<strong parentName="p">{`sitebuilder`}</strong>{` at a middle-sized company based in Budapest.`}</p>
    <p>{`During working hours  I'm maintaining the company's website, which is made with a static site generator, called Jekyll and I mostly writes HTML and CSS with a few lines of JavaScript.`}</p>
    <p>{`My main goal is to share my knowledge and raise awareness about the importance of accessibility, because I want everyone who surfs the internet to feel comfortable.`}</p>
    <p>{`Please, if you find any bugs on this site, share it with me, because I want to fix it, and I learn better from my mistakes. :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      